<div class="wrapper align-items-center d-flex mt-3" *ngFor="let airPorts of ODs; let index = index">
  <div class="d-flex align-items-center buttons-wrapper">
    <div class="btn-group dropdown">
      <button class="btn btn-light">
        {{ airPorts.departureAirPorts.value}}
      </button>
    </div>
    <div class="mx-2">→</div>
    <div class="btn-group dropdown" ngbDropdown #arrivalDropdown="ngbDropdown"
         *ngIf="airPorts.arrivalAirPorts.length && airPorts.arrivalAirPorts.length !== 1; else arrivalOne">
      <button class="btn btn-light" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
        {{ airPorts.arrivalSelected.value ? airPorts.arrivalSelected.value : 'None' }}
      </button>
      <div ngbDropdownMenu>
        <a
          *ngFor="let airport of airPorts.arrivalAirPorts;"
          (click)="selectAirport('arrival' , airport, index); arrivalDropdown.close();"
          class="dropdown-item inversed"
        >{{ airport.value }}</a>
      </div>
    </div>
    <ng-template #arrivalOne>
      <button class="btn btn-light">
        <span>{{ airPorts.arrivalSelected.value }}</span>
      </button>
    </ng-template>
  </div>
  <div class="date-block ml-1" *ngIf="airPorts.departureDate">
    <span>{{airPorts.departureDate?.year}}-{{(airPorts.departureDate?.month + '').padStart(2, '0') }}-{{(airPorts.departureDate?.day + '').padStart(2, '0')}}</span>
  </div>
  <div class="ml-5" *ngIf="airPorts.flightNumber">
    <span>{{airPorts.flightNumber}}</span>
  </div>
</div>
