<div class="carbon-container"
     [ngbPopover]="carbonPopover"
     [triggers]="'hover'"
     placement="bottom"
     container="body"
     popoverClass="">
  <img class="carbon-icon" src="./assets/img/illustrations/carbon.svg" alt="carbon-icon">
</div>

<ng-template #carbonPopover>
  <div class="carbon-popover">
    <div class="carbon-popover-title">CARBON OFFSET:</div>
    <div class="carbon-popover-container">
      <div *ngFor="let cd of carbonData">
        <div class="carbon-popover-destination" *ngIf='cd?.origin && cd?.destination'>{{cd.origin}} <span>→</span> {{cd.destination}}</div>
        <div class="carbon-popover-info" *ngIf='cd?.footprint'><span>{{ cd.footprint }}</span> kgs per passenger</div>
      </div>
    </div>
  </div>
</ng-template>
