<div class="input-group mb-1">
  <input
    type="text"
    class="form-control amadeus-input"
    [placeholder]="inputPlaceholder"
    spellcheck="false"
    aria-label="Console search"
    #searchInputElement
    aria-describedby="basic-addon2"
    [(ngModel)]="inputValue"
    [ngClass]="{'active': !!inputValue}"
    (keyup.enter)="amadeusSearch()"
  >
</div>
<div class="error-in-step"*ngIf="errorInStep">{{errorInStep}}</div>
