<div class='d-flex w-100 h-auto'>
  <ng-container *ngIf="order.account?.travelerApprovalRequired">
    <div style="width: 50%;">
      <div class="approval-status {{order | getApprovalStatusClass : 'traveler'}}"
           [ngbPopover]="travelerApprovalStatus"
           [triggers]="'hover'"
           placement="top"
      ></div>
    </div>
  </ng-container>

  <ng-container *ngIf="order.account?.managerApprovalRequired">
    <div style="width: 50%;">
      <div class="approval-status {{order | getApprovalStatusClass : 'manager'}}"
           [ngbPopover]="managerApprovalStatus"
           [triggers]="'hover'"
           placement="top">
      </div>
    </div>
  </ng-container>
</div>

<ng-template #travelerApprovalStatus>
  <ng-container [ngSwitch]="order | getApprovalStatusClass : 'traveler'">
    <div *ngSwitchCase="'pending'">Traveler Pending</div>
    <div *ngSwitchCase="'rejected'">Traveler Rejected</div>
    <div *ngSwitchCase="'approved'">Traveler Approved</div>
  </ng-container>
</ng-template>
<ng-template #managerApprovalStatus>
  <ng-container [ngSwitch]="order | getApprovalStatusClass : 'manager'">
    <div *ngSwitchCase="'pending'">Manager Pending</div>
    <div *ngSwitchCase="'rejected'">Manager Rejected</div>
    <div *ngSwitchCase="'approved'">Manager Approved</div>
  </ng-container>
</ng-template>
