<div class="pnr-wp">
  <textarea [(ngModel)]="inputValue" spellcheck="false" (paste)="parseOrder()" (input)="onTextAreaInput($event)"
            placeholder="Insert your PNR"></textarea>
  <div class="parsing-actions d-flex col-12 pr-0 mt-2" [ngClass]="{'with-height': (isShowParseButton || parsedInitialData?.parsedSuccess === false || parsedInitialData?.parsedSuccess && !flightType.type)}"
       *ngIf="parsedInitialData || isShowParseButton">
    <div class="row-messages" *ngIf="parsedInitialData">
      <div class="info-message mb-0 pl-2 w-100" *ngIf="parsedInitialData?.parsedSuccess && !flightType.type"
           role="alert">
        <span class="ml-2">Please select a flight type first.</span>
      </div>
      <!--        <div-->
      <!--          *ngIf="flightType.type === 'RT' && parsedInitialData.segments.length > 2 && !payload.originDestinations.length"-->
      <!--          class="info-message mb-0 pl-2 w-100"-->
      <!--          role="alert">-->
      <!--          <span class="ml-2">Please select a destination for first flight.</span>-->
      <!--        </div>-->
      <div
        *ngIf="parsedInitialData?.parsedSuccess === false"
        class="alert-danger rounded h-100 d-flex align-items-center pl-2"
        role="alert">
        <span>PNR input is invalid.</span>
      </div>
    </div>
    <div class="btn-search-wp">
      <button *ngIf="isShowParseButton"
              class="btn btn-block btn-parse"
              (click)="parseOrder()"
      >Build search
      </button>
    </div>
  </div>
</div>

<ng-container *ngIf="parsedInitialData?.parsedSuccess">
  <div class="col-12 d-flex pnr-results mt-3">
    <div class="col-lg-5 col-xl-4">
      <h4 class="mb-3">Passengers:</h4>
      <div class="passenger" *ngFor="let passenger of parsedInitialData.passengers; let i = index">
        <span>{{passenger.name}} {{passenger.surname}}</span>
        <div ngbDropdown class="d-inline-block ml-3" [autoClose]="true">
          <button class="btn passenger-drop" ngbDropdownToggle
                  [disabled]="!allowNewSearch">{{ getTravelerTitle(passenger.type) }}</button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem *ngFor="let type of passTypes"
                    (click)="changePassengerType(i, type)">{{ getTravelerTitle(type) }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-7 col-xl-8">
      <h4 class="mb-3">Flight type:</h4>
      <div class="btn-group dropdown" ngbDropdown #flightTypeDropdown="ngbDropdown">
        <button class="btn btn-light" [ngClass]="{ 'highlighted-shadow':!flightType.type }" aria-haspopup="true"
                aria-expanded="false"
                ngbDropdownToggle>
          {{ flightTypeOptions[flightType.type] ? flightTypeOptions[flightType.type] : 'None' }}
        </button>
        <div ngbDropdownMenu>
          <a
            *ngFor="let flightTypeOption of (flightTypeOptions | ExcludeOptionsFromObj:excludedFlightTypeOptions | pairs);"
            (click)="setFlightType(flightTypeOption[0]); flightTypeDropdown.close();"
            class="dropdown-item inversed"
          >{{ flightTypeOption[1] }}</a>
        </div>
      </div>

      <div class="destination-numbers-select-wp d-flex align-items-center mt-3" *ngIf="flightType.type === FLIGHT_TYPE.MC">
        <h4 class="mb-0 mr-3">Select number of flights:</h4>
        <div class="btn-group dropdown" ngbDropdown #numberOfCitiesDropdown="ngbDropdown">
          <button class="btn btn-light" aria-haspopup="true" aria-expanded="false"
                  ngbDropdownToggle>
            {{ currentNumberOfCities ? currentNumberOfCities : 0 }}
          </button>
          <div ngbDropdownMenu>
            <a
              *ngFor="let number of numberOfCities;"
              (click)="onSelectNumberOfCities(number); numberOfCitiesDropdown.close();"
              class="dropdown-item inversed"
            >{{ number }}</a>
          </div>
        </div>
      </div>

      <app-pnr-dropdown (emitSelectedAirports)="setSelectedAirports($event)" [ODs]="selectedODs"
                        [isDisabled]="!allowNewSearch"></app-pnr-dropdown>
    </div>
  </div>
  <div class="btn-search-wp mt-2 ml-auto">
    <div class="loader-wrapper loader-wrapper-small" [hidden]="allowNewSearch">
      <app-loader></app-loader>
    </div>
    <button class="btn filter-btn btn-block btn-primary"
            [class.right-border]="allowNewSearch"
            (click)="allowNewSearch ? onSearch() : onAbort()"
            [disabled]="!payload.originDestinations.length || showErrorMatchAirports !== undefined">{{ allowNewSearch ? 'Search' : 'Abort' }}</button>
    <div *ngIf='allowNewSearch'
         (click)='openOfferHistoryModal()'>
      <div class="icon-wp left-boder">
        <i class="material-icons btn">restore</i>
      </div>
    </div>
  </div>
</ng-container>
