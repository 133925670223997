<div class="gds-details-wp">
  <textarea [(ngModel)]="inputValue" spellcheck="false" placeholder="Insert your command ..."></textarea>
  <div class="filter-btn-wp">
    <button
      class="btn filter-btn btn-primary"
    (click)="parseOrder()">Filter</button>
    <div class="error-container" *ngIf="errorInStep">
     <span class="alert-danger  rounded p-2 pr-4 pl-4"> {{errorInStep}} </span>
    </div>
  </div>
</div>
