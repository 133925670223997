import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-baggage',
  templateUrl: './baggage.component.html',
  styleUrls: ['./baggage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaggageComponent implements OnInit {

  @Input() flights: any;
  @Input() owner: string;
  @Input() currentPassengerRef: string;

  flightDisclosuresWayInfo = {};
  isCurrentPassengerHaveBaggage = false;
  BAGGAGE_CARRYON = ['BAGGAGE_CARRYON', 'CarryOn'];
  BAGGAGE_CHECKED = ['BAGGAGE_CHECKED', 'Checked'];

  constructor() {
  }

  ngOnInit() {
    this.prepereBaggageInfo();
  }


  baggageCountChange($event: number) {
  }

  private prepereBaggageInfo(): void {
    if (!this.flights) {
      return;
    }
    this.flightDisclosuresWayInfo = { };
    this.flights.forEach(flight => {
      this.flightDisclosuresWayInfo[flight.key] = {};
      /*const passengerBaggageInfoByFlight = this.preperePassengerBaggageInfoByFlight(flight);
      if (passengerBaggageInfoByFlight.length) {
        this.isCurrentPassengerHaveBaggage = true;
        this.flightDisclosuresWayInfo[flight.key] = {
          way: `${flight.departure.airportCode} → ${flight.arrival.airportCode}`,
          date: flight.departure.date,
          time: flight.departure.time,
          baggageInfo: passengerBaggageInfoByFlight,
          baggageCount: 0,
        };
      }*/
    });
  }

  private preperePassengerBaggageInfoByFlight(flight: any) {
    let passengerBaggageInfoByFlight = [];
    if (flight && flight.fares) {
      flight.fares.forEach(fare => {
        if (fare.passengerRefs && fare.passengerRefs.split(' ').includes(this.currentPassengerRef)) {
          let allowChooseAllBaggagesInFare = this.prepareMetadataToken(fare.descriptions);
          passengerBaggageInfoByFlight.push({
            type: fare.type,
            descriptions: fare.descriptions,
            allowChooseAllBaggagesInFare
          });
        }
      });
    }
    return passengerBaggageInfoByFlight;
  }

  private  prepareMetadataToken(fareDetailDescriptions: any[]): boolean {
    if (!fareDetailDescriptions || !fareDetailDescriptions.length) {
      return true;
    }
    for (let fareDetailDescription of fareDetailDescriptions) {
      if (fareDetailDescription.metadataToken === 'OR') {
        return false;
      }
    }
    return true;
  }
}
