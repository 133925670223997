import {Component, Input, OnInit} from '@angular/core';
import {CarbonOffset} from "../../interfaces/carbon-offset";

@Component({
  selector: 'app-carbon',
  templateUrl: './carbon.component.html',
  styleUrls: ['./carbon.component.scss']
})
export class CarbonComponent implements OnInit {

  @Input() carbonOffset: CarbonOffset[];
  @Input() flightKey: string;
  @Input() isPdf: boolean;


  constructor() { }
  carbonData: CarbonOffset[];

  ngOnInit(): void {
    this.carbonData = this.carbonOffset.filter(carbon => carbon.flightKey === this.flightKey);
  }

}
