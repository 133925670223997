<div class="segment-wp">
  <div class="segment-data right-shade">
    <div class="operating-carrier">
      <!-- <app-airline-img
        [src]="'assets/airline-img/providers/jpg/'+segment.operatingCarrier.airlineID+'.jpg'"
      ></app-airline-img> -->
    </div>
    <div class="seg-data-detail">
      <div class="route">
        <span class="flight-number">
          {{ segment?.flightNumber | formatFlightNumber}} -
        </span>
        <span [appTooltip]="segment.originDestination.departure.airportCode">
          {{segment.originDestination.departure.airportCode}}
        </span>
        <span>
          →
        </span>
        <span [appTooltip]="segment.originDestination.arrival.airportCode">
          {{segment.originDestination.arrival.airportCode}}
        </span>

      </div>
      <div class="fares" *ngIf="!fareOptionModel">
        Class:
        <ng-container *ngIf="segment.detail.classOfService.code">
          <b>{{ segment.detail.classOfService.code + ','}} </b>
        </ng-container>
        <ng-container *ngIf="segment.detail.classOfService.cabinDesignator">
          {{ segment.detail.classOfService.cabinDesignator + ','}}
        </ng-container>
        <ng-container *ngIf="segment.detail.classOfService.fare.priceClassName">
          {{segment.detail.classOfService.fare.priceClassName }}
        </ng-container>
<!--        <div class="show-fare-cabin" *ngIf="showFareCabin && segment?.detail?.classOfService?.code">-->
<!--          Fare Class: {{segment.detail.classOfService.code}}-->
<!--        </div>-->
        <div class="fare-item-wp">
          <div class="fare-item"
               *ngIf=" disclosures &&  disclosures.fareStandard ">
            <div class="fare-name"> {{  disclosures.fareStandard }} </div>
            <div class="fare-icons">
              <ng-container *ngIf=" disclosures.disclosures?.length">
                <app-air-icons-info
                  *ngFor="let desc of  disclosures.disclosures"
                  [icon]="desc">
                </app-air-icons-info>
                <app-air-icons-info
                  icon="contact_support"
                  [showPopup]="true"
                  [popupData]=" disclosures.disclosures">
                </app-air-icons-info>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="fares offer-builder-segment-item" *ngIf="fareOptionModel">
        <div class="available-classes-wp" *ngIf="fareOptionModel[segmentIndex]?.info?.length">
          <h4 class="title-flight-item">
            Available Classes
          </h4>
          <div class="classes-item-wp">
            <ng-container *ngFor="let item of fareOptionModel[segmentIndex].info; index as clsIdx">
              <app-offer-builder-available-items
                *ngIf="item.class"
                [cls]="item.class"
                [clsIdx]="clsIdx"
                [segmentCls]="fareOptionModel[segmentIndex]"
              ></app-offer-builder-available-items>
            </ng-container>
          </div>
        </div>

        <div class="available-fares-wp" *ngIf="fareOptionModel[segmentIndex]?.disclosures?.length">
          <h4 class="title-flight-item">
            Available Fares
          </h4>
          <div class="fares-item">
            <div class="fare-info" *ngFor="let dsc of fareOptionModel[segmentIndex]?.disclosures;">
              <div class="classes-item">
                <span>{{dsc?.name}}</span> <span *ngIf="dsc?.marketingName"> ({{dsc?.marketingName}})</span>
              </div>
              <div class="fare-icons">
                <ng-container *ngIf="dsc?.disclosures?.length">
                  <ng-container *ngFor="let desc of dsc?.disclosures">
                    <app-air-icons-info
                      *ngIf="desc.text"
                      [icon]="desc">
                    </app-air-icons-info>
                  </ng-container>
                  <app-air-icons-info
                    icon="contact_support"
                    [showPopup]="true"
                    [popupData]="dsc?.disclosures">
                  </app-air-icons-info>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="segment-time">
    <div class="flight-time-range">
      {{segment.originDestination.departure.time}} - {{segment.originDestination.arrival.time}}
      <span class="flight-time-duration">
        {{ !segment.detail?.duration ? '' : ('(' + (segment.detail.duration | parseDuration) + ')') }}
      </span>
    </div>
<!--    <div class="flight-date"-->
<!--         [class.next-day]="segment.originDestination.departure.date !== segment.originDestination.arrival.date"-->
<!--         [innerHTML]="segment | showSegmentDates"-->
<!--    ></div>-->
    <app-list-of-flight-stops
      *ngIf="segment.detail.stopLocations && segment.detail.stopLocations.length"
      [stopLocations]="segment.detail.stopLocations">
    </app-list-of-flight-stops>
  </div>
</div>
