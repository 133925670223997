import { Pipe, PipeTransform } from '@angular/core';
import { ORDER_REVIEW_STATUS } from 'app/shared/constants';

@Pipe({
  name: 'getApprovalStatusClass'
})
export class GetApprovalStatusClassPipe implements PipeTransform {

  transform(order: any, role: string, ...args: unknown[]): string {
    const traveler = 'traveler';
    switch (order.approveStatus || order.approvalStatus) {
      case ORDER_REVIEW_STATUS.TRAVELER_PENDING:
        return 'pending';
      case ORDER_REVIEW_STATUS.TRAVELER_REJECTED:
        return role === traveler ? 'rejected' : 'pending';
      case ORDER_REVIEW_STATUS.TRAVELER_APPROVED:
        return role === traveler ? 'approved' : 'pending';
      case ORDER_REVIEW_STATUS.MANAGER_PENDING:
        return role === traveler ? 'approved' : 'pending';
      case ORDER_REVIEW_STATUS.MANAGER_REJECTED:
        return role === traveler ? 'approved' : 'rejected';
      case ORDER_REVIEW_STATUS.CUSTOMER_OK:
        return 'approved';
    }
  }
}
