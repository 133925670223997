<ng-container *ngIf="isCurrentPassengerHaveBaggage">
  <h2>Baggage Allowance</h2>
  <ngb-tabset>
    <ng-container *ngFor="let flight of (flightDisclosuresWayInfo | keys)">
      <ng-container *ngIf="!!flightDisclosuresWayInfo[flight].way">
        <ngb-tab [id]="flightDisclosuresWayInfo[flight].way" [title]="flightDisclosuresWayInfo[flight].way">
          <ng-template ngbTabContent>
            <ng-container
              [ngTemplateOutlet]="baggageItem"
              [ngTemplateOutletContext]="{
               currentOwner: owner,
               flightInfo: flightDisclosuresWayInfo[flight],
               flightSegment: flight
            }"></ng-container>
          </ng-template>
        </ngb-tab>
      </ng-container>
    </ng-container>
  </ngb-tabset>
</ng-container>

<ng-template
  #baggageItem
  let-currentOwner='currentOwner'
  let-flightInfo='flightInfo'
  let-flightSegment='flightSegment'
>
  <div class="baggage-item-container">
    <div class="baggage-item-header">
      <div class="provider-image">
        <app-airline-img
          [airlineID]="currentOwner"
        ></app-airline-img>
      </div>
      <div class="flight-departure-date">
        <span class="icon">
          <i class="material-icons"> date_range</i>
        </span>
        <div class="flight-departure-date-detail">
          {{ flightInfo.date }}<span>({{flightInfo.time}})</span>
        </div>
      </div>
    </div>

    <div class="baggage-item-info">
      <div class="baggage-item-info-header">Included with fare</div>
      <ng-container *ngFor="let baggageInfo of flightInfo.baggageInfo; let idx = index">
        <ng-container
          [ngTemplateOutlet]="baggageInfoTemplate"
          [ngTemplateOutletContext]="{
            flightSegment: flightSegment,
            baggageType: baggageInfo.type,
            baggageInfoDescriptions: baggageInfo.descriptions,
            allowChooseAllBaggagesInFare: baggageInfo.allowChooseAllBaggagesInFare,
            baggageInfoIdx: idx
          }"
        ></ng-container>
      </ng-container>
    </div>
    <div class="baggage-item-info" *ngIf="false">
      <div class="baggage-item-info-header">Add additional baggage</div>
      <ng-container
        [ngTemplateOutlet]="baggageCounterTemplate"
        [ngTemplateOutletContext]="{
          baggageType: BAGGAGE_CHECKED,
          baggageCounterText: 'Checked bag',
          baggageCounterCount: 0
        }"></ng-container>
      <ng-container
        [ngTemplateOutlet]="baggageCounterTemplate"
        [ngTemplateOutletContext]="{
          baggageType: BAGGAGE_CARRYON,
          baggageCounterText: 'Carry on bag'
        }"></ng-container>
    </div>
  </div>
</ng-template>




<ng-template
  #baggageInfoTemplate
  let-flightSegment='flightSegment'
  let-baggageType='baggageType'
  let-baggageInfoDescriptions='baggageInfoDescriptions'
  let-allowChooseAllBaggagesInFare='allowChooseAllBaggagesInFare'
  let-baggageInfoIdx='baggageInfoIdx'
>
  <ng-container *ngIf="allowChooseAllBaggagesInFare; else onlyOneBaggageAvailibleTemplate">
    <div *ngFor="let baggageDescription of baggageInfoDescriptions" class="baggage-item-info-detail-container">
      <div class="baggage-item-info-detail p-55">
        <div class="detail-container">
          <ng-container
            [ngTemplateOutlet]="baggageIcon"
            [ngTemplateOutletContext]="{
              baggageType: baggageType
            }"
          ></ng-container>
          <div class="baggage-info-text">
            {{ baggageDescription.text }}
            <div
              *ngIf="baggageDescription.properties && baggageDescription.properties.length"
              class="baggage-info-text-icon"
            >
              <ng-container
                [ngTemplateOutlet]="baggagePropertiesInfoTemp"
                [ngTemplateOutletContext]="{ propertiesInfo: baggageDescription.properties }"
              ></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #onlyOneBaggageAvailibleTemplate>
    <div class="baggage-item-info-detail-container">
      <div class="baggage-item-info-detail-header">Plus one of the items below:</div>
      <div *ngFor="let baggageDescription of baggageInfoDescriptions; let idx = index"
           class="baggage-item-info-detail p-55">
        <div class="detail-container">
          <ng-container
            [ngTemplateOutlet]="baggageIcon"
            [ngTemplateOutletContext]="{
              baggageType: baggageType
            }"
          ></ng-container>
          <div class="baggage-info-text optional-baggage-item"
               [class.selected-optional-baggage-item]="baggageDescription.selected"
          >
            {{ baggageDescription.text }}
            <div
              *ngIf="baggageDescription.properties && baggageDescription.properties.length"
              class="baggage-info-text-icon"
            >
              <ng-container
                [ngTemplateOutlet]="baggagePropertiesInfoTemp"
                [ngTemplateOutletContext]="{ propertiesInfo: baggageDescription.properties }"
              ></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>


<ng-template
  #baggageCounterTemplate
  let-baggageType='baggageType'
  let-baggageCounterText='baggageCounterText'
  let-baggageCounterCount='baggageCounterCount'
>
  <div class="grid-container p-55">
    <div class="baggage-item-info-detail">
      <ng-container
        [ngTemplateOutlet]="baggageIcon"
        [ngTemplateOutletContext]="{
          baggageType: baggageType
        }"
      ></ng-container>
      <div class="baggage-info-text">
        {{ baggageCounterText }}
      </div>
    </div>

    <div class="baggage-counter">
      <div class="mr-1-6-rem">
        <app-counter
          [count]="baggageCounterCount"
          (emitCountChange)="baggageCountChange($event)"
        ></app-counter>
      </div>
      <div class="baggage-price">
        {{ baggageCounterCount ? baggageCounterCount : 0 }} {{ 'EUR' }}
      </div>
    </div>
  </div>
</ng-template>




<ng-template #baggageIcon let-baggageType='baggageType'>
  <span
    *ngIf="BAGGAGE_CHECKED.includes(baggageType)"
    class="baggage-icon icon"
  ><i class="material-icons">work</i>
  </span>

  <span
    *ngIf="BAGGAGE_CARRYON.includes(baggageType)"
    class="baggage-icon icon"
  ><i class="custom-baggage-icon"></i>
  </span>
</ng-template>


<ng-template
  #baggagePropertiesInfoTemp
  let-propertiesInfo='propertiesInfo'
>
  <i class="material-icons"
   [ngbPopover]="propertyInfoPopover"
   triggers="hover"
   placement="top auto">info_outline</i>

  <ng-template #propertyInfoPopover>
    <ng-container *ngFor="let propertyInfo of propertiesInfo">
      <div>{{propertyInfo.type }}: {{ propertyInfo.value }} {{ propertyInfo.UOM.toLowerCase() }}</div>
    </ng-container>
  </ng-template>
</ng-template>
