<button
  class="count-icon icon-secondary"
  (click)="decreaseCounter()"
><i
  class="material-icons"
  [ngStyle]="{
    'font-size': iconFontSize
  }">indeterminate_check_box</i>
</button>

<span
  class="count-text"
  [ngStyle]="{
    'font-size': textFontSize
  }">{{ count }}</span>

<button
  class="count-icon icon-primary"
  (click)="increaseCounter()"
><i
  class="material-icons"
  [ngStyle]="{
    'font-size': iconFontSize
  }">add_box</i>
</button>
